<template>
    <properties-block  v-bind="$props" v-on="$listeners">
<!--        <properties-component-text title="Content|Contenu" :value="block.content"/>-->
    </properties-block>
</template>

<script>
import PropertiesBlock from "@/components/editor/properties/blocks/properties-block.vue";

// properties-block-word-page #hot-reload-debug
export default {
    name: `properties-block-word-page`,
    components: {PropertiesBlock},
    extends: PropertiesBlock
}
</script>

<style lang="scss" scoped>
</style>
